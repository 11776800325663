<template>
  <div class="container index">
    <v-subheader>Stored Data</v-subheader>
    <div class="ma-5">
      <pre v-if="storedData">{{ storedData }}</pre>
      <span v-else>No Data</span>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data: () => {
    return {
      storedData: null,
      companyName: process.env.VUE_APP_COMPANY_NAME
    };
  },
  mounted() {
    const data = this.$route.params.data;
    if (data) {
      this.storedData = data;
      
      this.processStoredData();
    }
  },
  methods: {
    ...mapActions("messages", ["addErrorMessage"]),
    processStoredData(){
      if(this.storedData && this.storedData.owner != this.companyName){
        console.info('Unknown company ' + this.storedData.owner + ' owner detected...');
        return;
      }

      //check if business type prop exists
      if(this.storedData && this.storedData.businessType){
        const businessType = this.storedData.businessType;
        switch(businessType){
          case 'WorksheetEntry':
            this.redirectToWorksheet();
            break;
          default:
            console.log("Unknown business type")
        }
      }
    },
    redirectToWorksheet(){
      const worksheetId = this.storedData.worksheetId;
      const worksheetEntryId = this.storedData.entryId;

      this.$router.replace({ name: 'WorksheetScan', params: {id: worksheetId, entryId: worksheetEntryId} });
    }
  },
};
</script>

<style></style>
